export const ajaxPosts = (currentPage, loadMoreButton) => {
    // eslint-disable-next-line no-undef
    const url = data.ajax_url

    // fetch, expect json data
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            // eslint-disable-next-line no-undef
            'X-WP-Nonce': data.nonce,
        },
        body: `action=load_more&page=${currentPage}`,
    })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                window.ajaxdata.next_posts.posts = data.data.posts
            } else {
                // eslint-disable-next-line no-console
                console.error('Error: ' + data.error)
            }

            loadMoreButton.disabled = false
            loadMoreButton.setAttribute('data-current-page', data.data.next_page)
            loadMoreButton.classList.remove('btn--loading')
        })
}

export const renderPosts = (allPosts, template, containerSelector = '.client-row') => {
    const container = document.querySelector(containerSelector)
    if (!container) {
        // eslint-disable-next-line no-console
        console.error(`Container with selector "${containerSelector}" not found.`)

        return
    }

    allPosts.forEach((post) => {
        const renderedPost = template
            .replace('%title%', post.post_title)
            .replace('%title%', post.post_title)
            .replace('%link%', post.post_link)
            .replace('%desc%', post.post_excerpt)
            .replace('%img%', post.post_image.url)

        container.innerHTML += renderedPost
    })
}

export default ajaxPosts
