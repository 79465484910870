import './../../scss/blocks/_optipost.scss'

import { ajaxPosts, renderPosts } from '../components/ajaxPosts'

const loadMoreButton = document.querySelector('.post-block__button')

document.addEventListener('DOMContentLoaded', () => {

    // Check if there are posts to load, if not, remove the button
    if (!window.ajaxdata || !window.ajaxdata.next_posts || !Array.isArray(window.ajaxdata.next_posts.posts)
        || window.ajaxdata.next_posts.posts.length === 0) {
        loadMoreButton.remove()
    } else {
        loadMoreButton.addEventListener('click', () => {
            if (!loadMoreButton.disabled) {
                loadMoreButton.disabled = true
                loadMoreButton.classList.add('btn--loading')

                const currentPage = loadMoreButton.getAttribute('data-current-page')

                if (window.ajaxdata && window.ajaxdata.next_posts && Array.isArray(window.ajaxdata.next_posts.posts)) {
                    renderPosts(
                        window.ajaxdata.next_posts.posts,
                        window.ajaxdata.tpls.postCard,
                        '.post-block__items',
                    )
                }

                if (parseInt(currentPage) > 0) {
                    ajaxPosts(currentPage, loadMoreButton)
                } else {
                    loadMoreButton.remove()
                }
            }
        })
    }
})
